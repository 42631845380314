import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Spinner from '../layout/Spinner';
import {loadUser} from '../../actions/auth';
import {showSidebar} from '../../actions/sidebar';
import {showNavbar} from '../../actions/navbar'

import AnnuaLLineChart from "./AnnuaLLineChart";
import MonthlyBarChart from "./MonthlyBarChart";
import {ArrowDownRight, ArrowUpRight, Columns, DollarSign, HardDrive} from "react-feather";
import {getStatisticsData} from "../../actions/user";
import MoneyFormat from "../layout/MoneyFormat";
import ExpenseMonthlyBarChart from "./ExpenseMonthlyBarChart";
import ClientTransactionLineChart from "./ClientTransactionLineChart";
import SupplierTransactionLineChart from "./SupplierTransactionLineChart";
import MonthlyIncomeAndExpensesWithCalendar from "./MonthlyIncomeAndExpensesWithCalendar";

const Dashboard = ({
                       getCurrentUser,
                       loadUser,
                       user, loading, isAuthenticated,
                       role_name,
                       statistics,
                       getStatisticsData,
                       showSidebar
                   }) => {
    useEffect(() => {
        showSidebar();
        showNavbar();
    }, [getCurrentUser]);

    useEffect(() => {
        if (loading !== true, isAuthenticated == true) {
            getStatisticsData();
        }
    }, [getStatisticsData]);

    const [statData, setStateData] = useState({});
    useEffect(() => {
        setStateData(statistics);
    }, [statistics])

    const history = useHistory();
    useEffect(() => {
        if (!loading && user !== null && (role_name === 'manager' || role_name === 'storekeeper')) {
            history.replace('/profile')
        }
    }, [role_name, loading, user])

    return loading && user === null ? (
        <Spinner/>
    ) : (
        <div className="container-fluid p-0">
            <h3 className={"mb-2"}>Biznes statistikalari</h3>

            <div className="row">
                <div className="col-xl-6 col-xxl-5 d-flex">
                    <div className="w-100">
                        <div className="row">
                            <div className="col-sm-6">

                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col mt-0">
                                                <h5 className="card-title">Mahsulotlar soni</h5>
                                            </div>

                                            <div className="col-auto">
                                                <div className="stat text-primary">
                                                    {/*<i className="align-middle" data-feather="hard-drive"></i>*/}
                                                    <HardDrive/>
                                                </div>
                                            </div>
                                        </div>
                                        <h4 className="mt-1 mb-3">{statData?.numbers?.products}</h4>
                                    </div>
                                </div>

                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col mt-0">
                                                <h5 className="card-title">Klient Tashkilotlar </h5>
                                            </div>

                                            <div className="col-auto">
                                                <div className="stat text-primary">
                                                    {/*<i className="align-middle" data-feather="columns"></i>*/}
                                                    <Columns/>
                                                </div>
                                            </div>
                                        </div>
                                        <h4 className="mt-1 mb-3">
                                            {statData?.numbers?.clients}
                                        </h4>
                                    </div>
                                </div>


                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col mt-0">
                                                <h5 className="card-title">Kirim</h5>
                                            </div>

                                            <div className="col-auto">
                                                <div className="stat text-primary"
                                                     title={"Sotuvlar va transport yetkazmalari"}>
                                                    <DollarSign/>
                                                </div>
                                            </div>
                                        </div>
                                        <h4 className="mt-1 mb-3">
                                            <MoneyFormat value={statData?.numbers?.income?.toFixed(2)}/>
                                            <span className="badge text-dark">
                                                so'm
                                            </span>
                                        </h4>

                                        <div className="mb-0"
                                             title={`O'tgan oy: ${statData?.numbers?.incomes?.previous?.toFixed(2)} | Bu oy ${statData?.numbers?.incomes?.current?.toFixed(2)}`}>
                                            <span className="text-success">
                                                <ArrowUpRight size="16"/>
                                                <MoneyFormat
                                                    value={(parseInt(statData?.numbers?.incomes?.current) - parseInt(statData?.numbers?.incomes?.previous)).toFixed(2)}/>
                                            </span>
                                            <span className="text-muted">(O'tgan oy farqi)</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">

                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col mt-0">
                                                <h5 className="card-title">Materiallar soni</h5>
                                            </div>

                                            <div className="col-auto">
                                                <div className="stat text-primary">
                                                    {/*<i className="align-middle" data-feather="hard-drive"></i>*/}
                                                    <HardDrive/>
                                                </div>
                                            </div>
                                        </div>
                                        <h4 className="mt-1 mb-3">{statData?.numbers?.materials}</h4>
                                    </div>
                                </div>

                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col mt-0">
                                                <h5 className="card-title">Yetkazuvchilar soni</h5>
                                            </div>

                                            <div className="col-auto">
                                                <div className="stat text-primary">
                                                    {/*<i className="align-middle" data-feather="hard-drive"></i>*/}
                                                    <HardDrive/>
                                                </div>
                                            </div>
                                        </div>
                                        <h4 className="mt-1 mb-3">{statData?.numbers?.suppliers}</h4>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col mt-0">
                                                <h5 className="card-title">Chiqim</h5>
                                            </div>

                                            <div className="col-auto">
                                                <div className="stat text-primary"
                                                     title={"Material(Xom ashyo), Hodimlar oyliklari va harajatlar"}>
                                                    <DollarSign/>
                                                </div>
                                            </div>
                                        </div>
                                        <h4 className="mt-1 mb-3">
                                            <MoneyFormat value={statData?.numbers?.net_expense?.toFixed(2)}/>
                                            <span className="badge text-dark">
                                                so'm
                                            </span>
                                        </h4>
                                        <div className="mb-0"
                                             title={`O'tgan oy: ${statData?.numbers?.expenses?.previous?.toFixed(2)} | Bu oy ${statData?.numbers?.expenses?.current?.toFixed(2)}`}
                                        >
                                            <span className="text-danger">
                                                <ArrowDownRight size="16"/>
                                                <MoneyFormat
                                                    value={(parseInt(statData?.numbers?.expenses?.current) - parseInt(statData?.numbers?.expenses?.previous)).toFixed(2)}/>
                                            </span>
                                            <span className="text-muted">(O'tgan oy farq)</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-6 col-xxl-7">
                    <div className="card flex-fill w-100">
                        <div className="card-header">
                            <h5 className="card-title mb-0">Yillik material(harajat) va sotuv qiymati</h5>
                        </div>
                        <div className="card-body py-3">
                            <div className="chart chart-sm">
                                {/*<canvas id="chartjs-dashboard-line"></canvas>*/}
                                <AnnuaLLineChart metrics={statData?.annual_transactions || []}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">

                <div className="col-xl-6 col-xxl-6">
                    <div className="card flex-fill w-100">
                        <div className="card-header">
                            <h5 className="card-title mb-0">Yillik yetkazuvchi transaksiyalari</h5>
                        </div>
                        <div className="card-body py-3">
                            <div className="chart chart-sm">
                                {/*<canvas id="chartjs-dashboard-line"></canvas>*/}
                                <SupplierTransactionLineChart metrics={statData?.supplier_transactions || []}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 col-xxl-6">
                    <div className="card flex-fill w-100">
                        <div className="card-header">
                            <h5 className="card-title mb-0">Yillik klient transaksiyalari</h5>
                        </div>
                        <div className="card-body py-3">
                            <div className="chart chart-sm">
                                <ClientTransactionLineChart metrics={statData?.client_transactions || []}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-lg-6 col-xxl-6 d-flex">
                    <div className="card flex-fill w-100">
                        <div className="card-header">

                            <h5 className="card-title mb-0">Oylik sotuvlar soni</h5>
                        </div>
                        <div className="card-body d-flex w-100">
                            <div className="align-self-center chart chart-lg">
                                <MonthlyBarChart metrics={statData?.monthly_sales}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 col-xxl-6 d-flex order-2 order-xxl-3">
                    <MonthlyIncomeAndExpensesWithCalendar />
                </div>

                {/*<div className="card flex-fill w-100">*/}
                {/*    <div className="card-header">*/}
                {/*        <h5 className="card-title mb-0">TOP 3 ta Mahsulotlar</h5>*/}
                {/*    </div>*/}
                {/*    <div className="card-body d-flex">*/}
                {/*        <div className="align-self-center w-100">*/}
                {/*            <div className="py-3">*/}
                {/*                <div className="chart chart-xs">*/}
                {/*                    <ProductPieChart products={statData?.top_products}/>*/}
                {/*                </div>*/}
                {/*            </div>*/}

                {/*            <table className="table mb-0">*/}
                {/*                <tbody>*/}
                {/*                {*/}
                {/*                    statData?.top_products?.map((product, index) => (*/}
                {/*                        <tr key={index}>*/}
                {/*                            <td>{product?.name}</td>*/}
                {/*                            <td className="text-end">*/}
                {/*                                <MoneyFormat*/}
                {/*                                    value={product?.total_sales_sum || 0}/>*/}
                {/*                            </td>*/}
                {/*                        </tr>*/}
                {/*                    ))*/}
                {/*                }*/}
                {/*                </tbody>*/}
                {/*            </table>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>

            <div className="row">
                {/*<div className="col-12 col-md-6 col-xxl-6 d-flex order-2 order-xxl-3">*/}
                {/*    <div className="card flex-fill w-100">*/}
                {/*        <div className="card-header">*/}
                {/*            <h5 className="card-title mb-0">Harajatlar bo'yicha statistika</h5>*/}
                {/*        </div>*/}
                {/*        <div className="card-body d-flex">*/}
                {/*            <div className="align-self-center w-100">*/}
                {/*                <div className="py-3">*/}
                {/*                    <div className="chart chart-xs">*/}
                {/*                        <ProductPieChart expenses={statData?.expense_items}/>*/}
                {/*                    </div>*/}
                {/*                </div>*/}

                {/*                <table className="table mb-0">*/}
                {/*                    <tbody>*/}
                {/*                    {*/}
                {/*                        statData?.expense_items?.map((item, index) => (*/}
                {/*                            <tr key={index}>*/}
                {/*                                <td>{item?.name}</td>*/}
                {/*                                <td className="text-end">*/}
                {/*                                    <MoneyFormat*/}
                {/*                                        value={item?.sum || 0}/>*/}
                {/*                                </td>*/}
                {/*                            </tr>*/}
                {/*                        ))*/}
                {/*                    }*/}
                {/*                    </tbody>*/}
                {/*                </table>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                    <div className="card flex-fill w-100">
                        <div className="card-header">

                            <h5 className="card-title mb-0">Oylik harajatlar ko'rsatkichi</h5>
                        </div>
                        <div className="card-body d-flex w-100">
                            <div className="align-self-center chart chart-lg">
                                <ExpenseMonthlyBarChart metrics={statData?.monthly_expenses}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
        ;
};

Dashboard.propTypes = {
    showSidebar: PropTypes.func.isRequired,
    loadUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    // user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    user: state.auth.user,
    role_name: state.auth.user?.role_name,
    loading: state.auth.loading,
    isAuthenticated: state.auth.isAuthenticated,
    navbar: state.navbar,
    statistics: state.user.statistics,
});

export default connect(mapStateToProps, {loadUser, getStatisticsData, showSidebar, showNavbar})(
    Dashboard
);
