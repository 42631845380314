import React, {Fragment, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/Button';
import {
    addMaterialImport,
    getMaterialOptions,
    getSupplierOptions,
    clearErrors
} from "../../actions/material";
import Select from 'react-select';
import {FormGroup, Input, Label} from "reactstrap";
import {Accordion, Form} from "react-bootstrap";
import {getParams, getSelectedOption} from "../../utils/hooks/getParams";
import {getFirmOptions} from "../../actions/user";
import moment from "moment";

const MaterialImportForm = ({
                                material_units,
                                loading,
                                materials,
                                error,
                                supplier_options,
                                material_options,
                                firm_options,
                                material_warehouses_options,
                                clearErrors,
                                addMaterialImport,
                                getSupplierOptions,
                                getMaterialOptions,
                            }) => {

    useEffect(() => {
        getFirmOptions();
        getSupplierOptions();
    }, [getFirmOptions, getSupplierOptions]);

    const [supplierOptions, setSupplierOptions] = useState([]);
    const [materialOptions, setMaterialOptions] = useState([]);
    const [firmOptions, setFirmOptions] = useState([]);

    const formRef = useRef();
    const defaultValues = {
        supplier_id: '',
        material_id: '',
        quantity: 0,
        price: 0,
        date: '',
        is_for_special_sale: false,

    };
    const [inputs, setInputs] = useState(defaultValues);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        let options = [];
        supplier_options?.forEach((item) => options.push({
            label: item?.name,
            value: item?.id
        }));
        setSupplierOptions(options);
    }, [supplier_options])

    useEffect(() => {

        let options = [];
        firm_options.forEach((item) => options.push({
            label: item.name,
            value: item.id
        }));
        setFirmOptions(options);
    }, [firm_options]);


    useEffect(() => {
        let options = [];
        material_options.forEach((item) => options.push({
            label: item.name,
            value: item.id
        }));
        setMaterialOptions(options);
    }, [material_options]);


    const handleChange = e => {
        if (e.target.name === 'quantity' && parseFloat(e.target.value) < 0) {
            e.target.value = Number(e.target.value) * -1;
        }
        if (e.target.name === 'price' && parseFloat(e.target.value) < 0) {
            e.target.value = Number(e.target.value) * -1;
        }
        setErrors({});
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
    }
    const handleSelect = (data, type) => {
        if (type.name == 'firm_id') {
            getMaterialOptions({firm_id: data.value});
        }
        setErrors({});
        setInputs(prevState => ({...prevState, [type.name]: data.value}))
    };

    const onBoolTooggle = (field) => {
        console.log(field);
        setInputs(prevState => ({...prevState, [field]: !prevState[field]}))
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const params = getParams(true)
        if (await addMaterialImport(inputs, params)) {
            setInputs({});
        } else {
            const errorsItems = error?.errors;
            let errorObject = {};
            if (error && error?.errors) {
                Object.keys(error?.errors).forEach((item, index) => errorObject[item] = errorsItems[item][0]);
            }
            setErrors(errorObject);
        }
    };


    useEffect(() => {

        if (loading === false) {
            if (error) {
                const errorsItems = error?.errors;
                let errorObject = {};
                if (error.hasOwnProperty('errors')) {
                    Object.keys(error.errors).forEach((item, index) => errorObject[item] = errorsItems[item][0]);
                }
                setErrors(errorObject);

            } else {
                clearErrors();
                setErrors({});
                setInputs(defaultValues);
            }
        }
    }, [error, materials]);

    return (
        <Fragment>

            <div className="col-12">

                <Form onSubmit={handleSubmit} ref={formRef}>

                    <FormGroup>
                        <Label for="supplier_id">
                            Yatkazuvchi(поставщик)
                        </Label>
                        <Select
                            name={'supplier_id'}
                            options={supplierOptions}
                            onChange={handleSelect}
                            value={getSelectedOption(inputs?.supplier_id, supplierOptions)}
                            required
                            defaultValue={{label: 'Tanlang', value: ''}}
                        />
                        <div className="error">
                            {errors?.supplier_id}
                        </div>
                    </FormGroup>

                    <FormGroup>
                        <Label for="firm_id">
                            Material Firma
                        </Label>
                        <Select
                            name={'firm_id'}
                            options={firmOptions}
                            onChange={handleSelect}
                            value={getSelectedOption(inputs?.firm_id, firmOptions)}
                            required
                            defaultValue={{label: 'Tanlang', value: ''}}
                        />
                    </FormGroup>

                    <div className="mb-2">
                        <label htmlFor="material_id">Material</label>
                        <Select
                            name={'material_id'}
                            options={materialOptions}
                            value={getSelectedOption(inputs?.material_id, materialOptions)}
                            onChange={handleSelect}
                            defaultValue={{label: 'Tanlang', value: ''}}
                        />
                        <div className="error">
                            {errors?.material_id}
                        </div>
                    </div>

                    <div className="mb-2">
                        <label htmlFor="quantity">Miqdori</label>
                        <Input
                            name="quantity"
                            value={inputs.quantity || ''}
                            type="number"
                            placeholder={1000}
                            onChange={handleChange}
                            required
                        />
                        <div className="error">
                            {errors?.quantity}
                        </div>
                    </div>

                    <div className="form-check form-switch">
                        <input className="form-check-input"
                               type="checkbox"
                               id="flexSwitchCheckChecked"
                               onChange={E => onBoolTooggle('is_for_special_sale')}
                               checked={inputs.is_for_special_sale}
                        />
                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked">
                            Maxsus sotuv uchun
                        </label>
                    </div>

                    {
                        !inputs.is_for_special_sale ? (
                            <div className="mb-2">
                                <label htmlFor="price">Narxi</label>
                                <Input
                                    name="price"
                                    value={inputs.price || ''}
                                    type="number"
                                    placeholder={1000}
                                    onChange={handleChange}
                                    required
                                />
                                <div className="error">
                                    {errors?.price}
                                </div>
                            </div>

                        ) : ""
                    }
                    
                    <div className="mb-3">
                        <label htmlFor="date">Sana</label>
                        <input
                            name="date"
                            className={'form-control'}
                            type="date"
                            value={inputs?.date || ''}
                            placeholder={'kiriting'}
                            onChange={handleChange}
                            max={moment().format('YYYY-MM-DD')}
                            // onKeyUp={e => e.target.oldValue = e.target.value}
                        />

                        <div className="error">
                            {errors?.date}
                        </div>
                    </div>



                    <div className="mb-2">
                        <Button className="" variant="primary" type="submit">Skladga qo'shish</Button>
                    </div>
                </Form>

            </div>
        </Fragment>
    );
};

MaterialImportForm.propTypes = {
    addMaterialImport: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
    getSupplierOptions: PropTypes.func.isRequired,
    getMaterialOptions: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    material: state.material,
    materials: state.material.materials,
    error: state.material.error,
    loading: state.material.loading,
    material_units: state.material.material_units,
    supplier_options: state.material.supplier_options,
    material_options: state.material.material_options,
    firm_options: state.user.firm_options,
});


export default connect(mapStateToProps, {
    addMaterialImport,
    clearErrors,
    getSupplierOptions,
    getMaterialOptions,
    getFirmOptions,
})(MaterialImportForm);
