export const SET_ALERT = 'SET_ALERT';
export const CLEAR_ALERTS = 'CLEAR_ALERTS';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const SET_LOADING = 'SET_LOADING';
export const UNSET_LOADING = 'UNSET_LOADING';

export const GET_STATISTICS = 'GET_STATISTICS';
export const GET_INCOME_AND_EXPENSES = 'GET_INCOME_AND_EXPENSES';

export const GET_PROFILES = 'GET_PROFILES';
export const GET_REPOS = 'GET_REPOS';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const ACCOUNT_DELETED = 'ACCOUNT_DELET ED';
export const UPDATE_LIKES = 'UPDATE_LIKES';

export const ADD_POST = 'ADD_POST';
export const DELETE_POST = 'DELETE_POST';
export const POST_ERROR = 'POST_ERROR';
export const GET_POST = 'GET_POST';
export const GET_POSTS = 'GET_POSTS';

export const ADD_COMMENT = 'ADD_COMMENT';
export const REMOVE_COMMENT = 'REMOVE_COMMENT';

export const SHOW_SIDEBAR = 'SHOW_SIDEBAR';
export const HIDE_SIDEBAR = 'HIDE_SIDEBAR';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const EXPAND_SIDEBAR = 'EXPAND_SIDEBAR';
export const COLLAPSE_SIDEBAR = 'COLLAPSE_SIDEBAR';
export const SET_ACTIVE_MENU_ITEM = 'SET_ACTIVE_MENU_ITEM';

export const SHOW_NAVBAR = 'SHOW_NAVBAR';
export const HIDE_NAVBAR = 'HIDE_NAVBAR';
export const HIDE_PROFILE = 'HIDE_PROFILE';
export const TOGGLE_PROFILE = 'TOGGLE_PROFILE';

export const ADD_EMPLOYEE = 'ADD_EMPLOYEE';
export const REMOVE_DELETE_EMPLOYEE = 'REMOVE_DELETE_EMPLOYEE';
export const REMOVE_UPDATE_EMPLOYEE = 'REMOVE_UPDATE_EMPLOYEE';
export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE';
export const EMPLOYEE_ERROR = 'EMPLOYEE_ERROR';
export const GET_EMPLOYEE = 'GET_EMPLOYEE';
export const GET_FIRM_ID_DATA = 'GET_FIRM_ID_DATA';
export const GET_EMPLOYEES = 'GET_EMPLOYEES';
export const GET_FILTERED_EMPLOYEES = 'GET_FILTERED_EMPLOYEES';
export const GET_FILTERED_EMPLOYEE_SALARY = 'GET_FILTERED_EMPLOYEE_SALARY';
export const SET_DELETE_EMPLOYEE_ID = 'SET_DELETE_EMPLOYEE_ID';
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_UPDATE_EMPLOYEE_ID = 'SET_UPDATE_EMPLOYEE_ID';

export const ADD_USER = 'ADD_USER';
export const DELETE_USER = 'DELETE_USER';
export const USER_ERROR = 'USER_ERROR';
export const GET_USER_ACTIVITIES = 'GET_USER_ACTIVITIES';
export const GET_USER_ROL_ID_DATA = 'GET_USER_ROL_ID_DATA';
export const GET_USER = 'GET_USER';
export const GET_USERS = 'GET_USERS';
export const REMOVE_DELETE_USER = 'REMOVE_DELETE_USER';
export const REMOVE_UPDATE_USER = 'REMOVE_UPDATE_USER';
export const SET_DELETE_USER_ID = 'SET_DELETE_USER_ID';
export const SET_UPDATE_USER_ID = 'SET_UPDATE_USER_ID';
export const GET_FILTERED_USERS = 'GET_FILTERED_USERS';


export const ADD_CLIENT = 'ADD_CLIENT';
export const ADD_CLIENT_TRANSACTION = 'ADD_CLIENT_TRANSACTION';
export const REMOVE_DELETE_CLIENT = 'REMOVE_DELETE_CLIENT';
export const REMOVE_UPDATE_CLIENT = 'REMOVE_UPDATE_CLIENT';
export const CLIENT_ERROR = 'CLIENT_ERROR';
export const GET_CLIENT = 'GET_CLIENT';
export const GET_CLIENTS = 'GET_CLIENTS';
export const GET_CLIENT_TRANSACTIONS = 'GET_CLIENT_TRANSACTIONS';
export const GET_CLIENTS_TRANSACTIONS = 'GET_CLIENTS_TRANSACTIONS';
export const GET_FILTERED_CLIENT_TRANSACTION = 'GET_FILTERED_CLIENT_TRANSACTION';
export const GET_AVAILABLE_CLIENTS = 'GET_AVAILABLE_CLIENTS';
export const GET_AVAILABLE_SUPPLIERS = 'GET_AVAILABLE_SUPPLIERS';
export const SET_UPDATE_CLIENT_TRANSACTION_ID = 'SET_UPDATE_CLIENT_TRANSACTION_ID';
export const REMOVE_UPDATE_CLIENT_TRANSACTION = 'REMOVE_UPDATE_CLIENT_TRANSACTION';
export const SET_DELETE_CLIENT_ID = 'SET_DELETE_CLIENT_ID';
export const SET_UPDATE_CLIENT_ID = 'SET_UPDATE_CLIENT_ID';


export const GET_CLIENT_SALES = 'GET_CLIENT_SALES';
export const GET_SPECIAL_CLIENT = 'GET_SPECIAL_CLIENT';
export const GET_CLIENT_OPTIONS = 'GET_CLIENT_OPTIONS';
export const REMOVE_DELETE_CLIENT_SALE = 'REMOVE_DELETE_CLIENT_SALE';
export const REMOVE_UPDATE__CLIENT_SALE = 'REMOVE_UPDATE__CLIENT_SALE';
export const ADD_CLIENT_SALE = 'ADD_CLIENT_SALE';
export const GET_FILTERED_CLIENT_SALES = 'GET_FILTERED_CLIENT_SALES';
export const SET_DELETE_CLIENT_SALE_ID = 'SET_DELETE_CLIENT_SALE_ID';
export const SET_UPDATE_CLIENT_SALE_ID = 'SET_UPDATE_CLIENT_SALE_ID';


export const GET_AVAILABLE_CLIENT = 'GET_AVAILABLE_CLIENT';
export const AVAILABLE_CLIENT_ERROR = 'AVAILABLE_CLIENT_ERROR';
export const ADD_SUPPLIER = 'ADD_SUPPLIER';
export const REMOVE_DELETE_SUPPLIER = 'REMOVE_DELETE_SUPPLIER';
export const REMOVE_UPDATE_SUPPLIER = 'REMOVE_UPDATE_SUPPLIER';
export const CLEAR_FILTERED_PRODUCTS = 'CLEAR_FILTERED_PRODUCTS';
export const SUPPLIER_ERROR = 'CLIENT_ERROR';
export const GET_SUPPLIER = 'GET_SUPPLIER';
export const GET_UPDATE_SUPPLIER = 'GET_UPDATE_SUPPLIER';
export const GET_SUPPLIERS = 'GET_SUPPLIERS';
export const GET_SUPPLIER_TRANSACTIONS = 'GET_SUPPLIER_TRANSACTIONS';
export const GET_SUPPLIERS_MATERIALS_TRANSACTIONS = 'GET_SUPPLIERS_MATERIALS_TRANSACTIONS';
export const GET_SUPPLIER_MATERIALS_TRANSACTIONS = 'GET_SUPPLIER_MATERIALS_TRANSACTIONS';
export const GET_PRODUCTS_MATERIALS_TRANSACTIONS = 'GET_PRODUCTS_MATERIALS_TRANSACTIONS';
export const GET_PRODUCT_MATERIALS_TRANSACTIONS = 'GET_PRODUCT_MATERIALS_TRANSACTIONS';
export const GET_SUPPLIERS_TRANSACTIONS = 'GET_SUPPLIERS_TRANSACTIONS';
export const ADD_SUPPLIER_TRANSACTION = 'ADD_SUPPLIER_TRANSACTION';
export const GET_FILTERED_SUPPLIER_SALARY = 'GET_FILTERED_SUPPLIER_SALARY';
export const SET_DELETE_SUPPLIER_ID = 'SET_DELETE_SUPPLIER_ID';
export const SET_UPDATE_SUPPLIER_ID = 'SET_UPDATE_SUPPLIER_ID';


export const GET_MATERIALS_WAREHOUSES_ID_DATA = 'GET_MATERIALS_WAREHOUSES_ID_DATA';
export const GET_SUPPLIER_MATERIAL_IMPORTS = 'GET_SUPPLIER_MATERIAL_IMPORTS';
export const REMOVE_DELETE_SUPPLIER_MATERIAL = 'REMOVE_DELETE_SUPPLIER_MATERIAL';
export const REMOVE_UPDATE_SUPPLIER_MATERIAL = 'REMOVE_UPDATE_SUPPLIER_MATERIAL';
export const ADD_SUPPLIER_MATERIAL = 'ADD_TRANSPORT_SUPPLIER_MATERIAL';
export const GET_FILTERED_SUPPLIER_MATERIAL = 'GET_FILTERED_SUPPLIER_MATERIAL';
export const SET_DELETE_SUPPLIER_MATERIAL_ID = 'SET_DELETE_SUPPLIER_MATERIAL_ID';
export const SET_UPDATE_SUPPLIER_MATERIAL_ID = 'SET_UPDATE_SUPPLIER_MATERIAL_ID';


export const GET_AVAILABLE_MANAGER_USER = 'GET_AVAILABLE_MANAGER_USER';
export const AVAILABLE_MANAGER_USER_ERROR = 'AVAILABLE_MANAGER_USER_ERROR';
export const ADD_FIRM = 'ADD_FIRM';
export const REMOVE_DELETE_FIRM = 'REMOVE_DELETE_FIRM';
export const REMOVE_UPDATE_FIRM = 'REMOVE_UPDATE_FIRM';
export const FIRM_ERROR = 'FIRM_ERROR';
export const GET_FIRM = 'GET_FIRM';
export const GET_FIRMS = 'GET_FIRMS';
export const SET_DELETE_FIRM_ID = 'SET_DELETE_FIRM_ID';
export const SET_UPDATE_FIRM_ID = 'SET_UPDATE_FIRM_ID';
export const GET_FIRM_OPTIONS = 'GET_FIRM_OPTIONS';

export const GET_AVAILABLE_STROKEEPER_USER = 'GET_AVAILABLE_STROKEEPER_USER';
export const AVAILABLE_STROKEEPER_USER_ERROR = 'AVAILABLE_STROKEEPER_USER_ERROR';
export const ADD_WAREHOUSE = 'ADD_WAREHOUSE';
export const GET_FILTERED_WAREHOUSE_THINGS = 'GET_FILTERED_WAREHOUSE_THINGS';
export const REMOVE_DELETE_WAREHOUSE = 'REMOVE_DELETE_WAREHOUSE';
export const REMOVE_UPDATE_WAREHOUSE = 'REMOVE_UPDATE_WAREHOUSE';
export const WAREHOUSE_ERROR = 'WAREHOUSE_ERROR';
export const GET_WAREHOUSE = 'GET_WAREHOUSE';
export const GET_WAREHOUSES = 'GET_WAREHOUSES';
export const SET_DELETE_WAREHOUSE_ID = 'SET_DELETE_WAREHOUSE_ID';
export const SET_UPDATE_WAREHOUSE_ID = 'SET_UPDATE_WAREHOUSE_ID';

export const GET_AVAILABLE_DRIVER_EMPLOYEE = 'GET_AVAILABLE_DRIVER_EMPLOYEE';
export const AVAILABLE_DRIVER_EMPLOYEE_ERROR = 'AVAILABLE_DRIVER_EMPLOYEE_ERROR';
export const ADD_TRANSPORT = 'ADD_TRANSPORT';
export const REMOVE_DELETE_TRANSPORT = 'REMOVE_DELETE_TRANSPORT';
export const REMOVE_UPDATE_TRANSPORT = 'REMOVE_UPDATE_TRANSPORT';
export const TRANSPORT_ERROR = 'TRANSPORT_ERROR';
export const GET_TRANSPORT = 'GET_TRANSPORT';
export const GET_TRANSPORTS = 'GET_TRANSPORTS';
export const GET_FILTERED_TRANSPORTS = 'GET_FILTERED_TRANSPORTS';
export const SET_DELETE_TRANSPORT_ID = 'SET_DELETE_TRANSPORT_ID';
export const SET_UPDATE_TRANSPORT_ID = 'SET_UPDATE_TRANSPORT_ID';

export const GET_TRANSPORT_PRODUCT_DELIVERIES = 'GET_TRANSPORT_PRODUCT_DELIVERIES';
export const REMOVE_DELETE_TRANSPORT_PRODUCT_DELIVERY = 'REMOVE_DELETE_TRANSPORT_PRODUCT_DELIVERY';
export const REMOVE_UPDATE_TRANSPORT_PRODUCT_DELIVERY = 'REMOVE_UPDATE_TRANSPORT_PRODUCT_DELIVERY';
export const ADD_TRANSPORT_PRODUCT_DELIVERY = 'ADD_TRANSPORT_PRODUCT_DELIVERY';
export const GET_FILTERED_TRANSPORT_PRODUCT_DELIVERIES = 'GET_FILTERED_TRANSPORTS';
export const SET_DELETE_TRANSPORT_PRODUCT_DELIVERY_ID = 'SET_DELETE_TRANSPORT_PRODUCT_DELIVERY_ID';
export const SET_UPDATE_TRANSPORT_PRODUCT_DELIVERY_ID = 'SET_UPDATE_TRANSPORT_PRODUCT_DELIVERY_ID';

export const ADD_UNIT = 'ADD_UNIT';
export const UNIT_ERROR = 'UNIT_ERROR';
export const GET_UNIT = 'GET_UNIT';
export const GET_UNITS = 'GET_UNITS';
export const REMOVE_DELETE_UNIT = 'REMOVE_DELETE_UNIT';
export const REMOVE_UPDATE_UNIT = 'REMOVE_UPDATE_UNIT';
export const SET_DELETE_UNIT_ID = 'SET_DELETE_UNIT_ID';
export const SET_UPDATE_UNIT_ID = 'SET_UPDATE_UNIT_ID';

export const GET_UNITS_DATA = 'GET_UNITS_DATA';
export const GET_UNITS_DATA_ERROR = 'GET_UNIT_DATA_ERROR';
export const ADD_UNIT_ALTERNATIVE = 'ADD_UNIT_ALTERNATIVE';
export const UNIT_ALTERNATIVE_ERROR = 'UNIT_ALTERNATIVE_ERROR';
export const GET_UNIT_ALTERNATIVE = 'GET_UNIT_ALTERNATIVE';
export const GET_UNIT_ALTERNATIVES = 'GET_UNIT_ALTERNATIVES';
export const REMOVE_DELETE_UNIT_ALTERNATIVE = 'REMOVE_DELETE_UNIT_ALTERNATIVE';
export const REMOVE_UPDATE_UNIT_ALTERNATIVE = 'REMOVE_UPDATE_UNIT_ALTERNATIVE';
export const SET_DELETE_UNIT_ALTERNATIVE_ID = 'SET_DELETE_UNIT_ALTERNATIVE_ID';
export const SET_UPDATE_UNIT_ALTERNATIVE_ID = 'SET_UPDATE_UNIT_ALTERNATIVE_ID';


export const GET_WAREHOUSES_DATA = 'GET_WAREHOUSES_DATA';
export const GET_PRODUCT_OPTIONS = 'GET_PRODUCT_OPTIONS';
export const GET_UNIT_OPTIONS = 'GET_UNIT_OPTIONS';
export const GET_MATERIAL_OPTIONS = 'GET_MATERIAL_OPTIONS';
export const GET_MATERIALS_ON_PRODUCT = 'GET_MATERIALS_ON_PRODUCT';
export const GET_PRODUCT_TEMPLATES_ON_PRODUCTS = 'GET_PRODUCT_TEMPLATES_ON_PRODUCTS';
export const GET_PRODUCT_WAREHOUSES_ON_PRODUCTS = 'GET_PRODUCT_WAREHOUSES_ON_PRODUCTS';
export const PRODUCT_UNITS_DATA_ERROR = 'PRODUCT_UNITS_DATA_ERROR';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const REMOVE_DELETE_PRODUCT = 'REMOVE_DELETE_PRODUCT';
export const REMOVE_UPDATE_PRODUCT = 'REMOVE_UPDATE_PRODUCT';
export const REMOVE_UPDATE_PRODUCT_PRODUCTION = 'REMOVE_UPDATE_PRODUCT_PRODUCTION';
export const REMOVE_UPDATE_PRODUCT_SALE = 'REMOVE_UPDATE_PRODUCT_SALE';
export const REMOVE_DELETE_PRODUCT_SALE = 'REMOVE_DELETE_PRODUCT_SALE';
export const PRODUCT_ERROR = 'PRODUCT_ERROR';
export const PRODUCT_NOT_EXIST = 'PRODUCT_NOT_EXIST';
export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCT_PRODUCTIONS = 'GET_PRODUCT_PRODUCTIONS';
export const GET_PRODUCT_SALES = 'GET_PRODUCT_SALES';
export const GET_FILTERED_PRODUCT_PRODUCTION = 'GET_FILTERED_PRODUCT_PRODUCTION';
export const GET_FILTERED_PRODUCT_SALE = 'GET_FILTERED_PRODUCT_SALE';
export const ADD_PRODUCT_PRODUCTION = 'ADD_PRODUCT_PRODUCTION';
export const ADD_PRODUCT_SALE = 'ADD_PRODUCT_SALE';
export const GET_FILTERED_PRODUCTS = 'GET_FILTERED_PRODUCTS';
export const SET_DELETE_PRODUCT_ID = 'SET_DELETE_PRODUCT_ID';
export const SET_UPDATE_PRODUCT_ID = 'SET_UPDATE_PRODUCT_ID';
export const SET_UPDATE_PRODUCT_PRODUCTION_ID = 'SET_UPDATE_PRODUCT_PRODUCTION_ID';
export const SET_DELETE_PRODUCT_PRODUCTION_ID = 'SET_DELETE_PRODUCT_PRODUCTION_ID';
export const SET_DELETE_PRODUCT_SALE_ID = 'SET_DELETE_PRODUCT_SALE_ID';
export const SET_UPDATE_PRODUCT_SALE_ID = 'SET_UPDATE_PRODUCT_SALE_ID';

export const GET_SUPPLIER_TRANSACTION_SUPPLIERS_DATA = 'GET_SUPPLIER_TRANSACTION_SUPPLIERS_DATA';
export const REMOVE_DELETE_SUPPLIER_TRANSACTION = 'REMOVE_DELETE_SUPPLIER_TRANSACTION';
export const REMOVE_UPDATE_SUPPLIER_TRANSACTION = 'REMOVE_UPDATE_SUPPLIER_TRANSACTION';
export const REMOVE_UPDATE_SUPPLIER_TRANSACTION_FOR_SUPPLIER = 'REMOVE_UPDATE_SUPPLIER_TRANSACTION_FOR_SUPPLIER';
export const SUPPLIER_TRANSACTION_ERROR = 'SUPPLIER_TRANSACTION_ERROR';
export const GET_SUPPLIER_TRANSACTION = 'GET_SUPPLIER_TRANSACTION';
export const GET_FILTERED_SUPPLIER_TRANSACTIONS = 'GET_FILTERED_SUPPLIER_TRANSACTIONS';
export const SET_DELETE_SUPPLIER_TRANSACTION_ID = 'SET_DELETE_SUPPLIER_TRANSACTION_ID';
export const SET_UPDATE_SUPPLIER_TRANSACTION_ID = 'SET_UPDATE_SUPPLIER_TRANSACTION_ID';
export const SET_UPDATE_SUPPLIER_TRANSACTION_FOR_SUPPLIER_ID = 'SET_UPDATE_SUPPLIER_TRANSACTION_FOR_SUPPLIER_ID';

export const ADD_PRODUCT_DELIVERY = 'ADD_PRODUCT_DELIVERY';
export const REMOVE_DELETE_PRODUCT_PRODUCTION = 'REMOVE_DELETE_PRODUCT_PRODUCTION';
export const REMOVE_DELETE_PRODUCT_DELIVERY = 'REMOVE_DELETE_PRODUCT_DELIVERY';
export const REMOVE_UPDATE_PRODUCT_DELIVERY = 'REMOVE_UPDATE_PRODUCT_DELIVERY';
export const PRODUCT_DELIVERY_ERROR = 'PRODUCT_DELIVERY_ERROR';
export const GET_CLIENT_AND_DRIVER_ID_DATA = 'GET_CLIENT_AND_DRIVER_ID_DATA';
export const GET_PRODUCT_DELIVERY = 'GET_PRODUCT_DELIVERY';
export const GET_PRODUCT_DELIVERIES = 'GET_PRODUCT_DELIVERIES';
export const GET_FILTERED_PRODUCT_DELIVERIES = 'GET_FILTERED_PRODUCT_DELIVERIES';
export const SET_DELETE_PRODUCT_DELIVERY_ID = 'SET_DELETE_PRODUCT_DELIVERY_ID';
export const SET_UPDATE_PRODUCT_DELIVERY_ID = 'SET_UPDATE_PRODUCT_DELIVERY_ID';

export const ADD_SALE = 'ADD_SALE';
export const REMOVE_DELETE_SALE = 'REMOVE_DELETE_SALE';
export const REMOVE_UPDATE_SALE = 'REMOVE_UPDATE_SALE';
export const SALE_ERROR = 'SALE_ERROR';
export const GET_PRODUCT_CLIENT_AND_SELLER_ID_DATA = 'GET_PRODUCT_CLIENT_AND_SELLER_ID_DATA';
export const GET_SALE = 'GET_SALE';
export const GET_SALES = 'GET_SALES';
export const GET_FILTERED_SALES = 'GET_FILTERED_SALES';
export const SET_DELETE_SALE_ID = 'SET_DELETE_SALE_ID';
export const SET_UPDATE_SALE_ID = 'SET_UPDATE_SALE_ID';

export const GET_PRODUCT_TEMPLATE_UNITS_DATA = 'GET_PRODUCT_TEMPLATE_UNITS_DATA';
export const GET_PRODUCT_TEMPLATE_UNIT_ALTERNATIVES_DATA = 'GET_PRODUCT_TEMPLATE_UNIT_ALTERNATIVES_DATA';
export const PRODUCT_TEMPLATE_UNITS_ERROR = 'PRODUCT_TEMPLATE_UNITS_ERROR';
export const ADD_PRODUCT_TEMPLATE = 'ADD_PRODUCT_TEMPLATE';
export const REMOVE_DELETE_PRODUCT_TEMPLATE = 'REMOVE_DELETE_PRODUCT_TEMPLATE';
export const REMOVE_UPDATE_PRODUCT_TEMPLATE = 'REMOVE_UPDATE_PRODUCT_TEMPLATE';
export const PRODUCT_TEMPLATE_ERROR = 'PRODUCT_TEMPLATE_ERROR';
export const GET_PRODUCT_TEMPLATE = 'GET_PRODUCT_TEMPLATE';
export const GET_PRODUCT_TEMPLATES = 'GET_PRODUCT_TEMPLATES';
export const GET_PRODUCTS_ON_PRODUCT_TEMPLATES = 'GET_PRODUCTS_ON_PRODUCT_TEMPLATES';
export const GET_MATERIALS_ON_PRODUCT_TEMPLATES = 'GET_MATERIALS_ON_PRODUCT_TEMPLATES';
export const SET_DELETE_PRODUCT_TEMPLATE_ID = 'SET_DELETE_PRODUCT_TEMPLATE_ID';
export const SET_UPDATE_PRODUCT_TEMPLATE_ID = 'SET_UPDATE_PRODUCT_TEMPLATE_ID';

export const GET_MATERIAL_UNITS_DATA = 'GET_MATERIAL_UNITS_DATA';
export const MATERIAL_UNITS_DATA_ERROR = 'MATERIAL_UNITS_DATA_ERROR';
export const ADD_MATERIAL = 'ADD_MATERIAL';
export const REMOVE_DELETE_MATERIAL = 'REMOVE_DELETE_MATERIAL';
export const REMOVE_UPDATE_MATERIAL = 'REMOVE_UPDATE_MATERIAL';
export const MATERIAL_ERROR = 'MATERIAL_ERROR';
export const GET_MATERIALS_TRANSACTIONS = 'GET_MATERIALS_TRANSACTIONS';
export const GET_MATERIAL_TRANSACTIONS = 'GET_MATERIAL_TRANSACTIONS';
export const GET_MATERIAL = 'GET_MATERIAL';
export const GET_MATERIALS = 'GET_MATERIALS';
export const GET_MATERIALS_OPTIONS = 'GET_MATERIALS_OPTIONS';
export const SET_DELETE_MATERIAL_IMPORT_ID = 'SET_DELETE_MATERIAL_IMPORT_ID';
export const SET_DELETE_MATERIAL_IMPORT_ID_ON_MATERIAL_PAGE = 'SET_DELETE_MATERIAL_IMPORT_ID_ON_MATERIAL_PAGE';
export const GET_MATERIAL_IMPORTS = 'GET_MATERIAL_IMPORTS';
export const GET_MATERIAL_SPENDS = 'GET_MATERIAL_SPENDS';
export const CLEAR_MATERIAL_TRANSACTIONS = 'CLEAR_MATERIAL_TRANSACTIONS';
export const GET_WAREHOUSE_OPTIONS = 'GET_WAREHOUSE_OPTIONS';
export const ADD_SPAREPART_IMPORT = 'ADD_SPAREPART_IMPORT';
export const GET_SUPPLIER_OPTIONS = 'GET_SUPPLIER_OPTIONS';
export const SET_DELETE_MATERIAL_ID = 'SET_DELETE_MATERIAL_ID';
export const SET_UPDATE_MATERILA_ID = 'SET_UPDATE_MATERILA_ID';
export const ADD_MATERIAL_IMPORT = 'ADD_MATERIAL_IMPORT';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const GET_EMPLOYEES_ID_DATA = 'GET_EMPLOYEES_ID_DATA';
export const GET_PROFESSION_OPTIONS = 'GET_PROFESSION_OPTIONS';
export const EMPLOYEES_ID_ERROR = 'EMPLOYEES_ID_ERROR';
export const ADD_SALARY = 'ADD_SALARY';
export const SALARY_ERROR = 'SALARY_ERROR';
export const GET_SALARY = 'GET_SALARY';
export const GET_SALARIES = 'GET_SALARIES';
export const GET_SALARIES_DATA = 'GET_SALARIES_DATA';
export const GET_EMPLOYEE_TRANSACTIONS = 'GET_EMPLOYEE_TRANSACTIONS';
export const GET_PRODUCTS_TRANSACTIONS = 'GET_PRODUCTS_TRANSACTIONS';
export const GET_PRODUCT_TRANSACTIONS = 'GET_PRODUCT_TRANSACTIONS';
export const GET_EMPLOYEES_TRANSACTIONS = 'GET_EMPLOYEES_TRANSACTIONS';
export const GET_AVAILABLE_MANAGERS = 'GET_AVAILABLE_MANAGERS';
export const REMOVE_DELETE_SALARY = 'REMOVE_DELETE_SALARY';
export const REMOVE_UPDATE_SALARY = 'REMOVE_UPDATE_SALARY';
export const SET_DELETE_SALARY_ID = 'SET_DELETE_SALARY_ID';
export const SET_UPDATE_SALARY_ID = 'SET_UPDATE_SALARY_ID';

export const ADD_EMPLOYEE_PAYMENT = 'ADD_EMPLOYEE_PAYMENT';
export const EMPLOYEE_PAYMENT_ERROR = 'EMPLOYEE_PAYMENT_ERROR';
export const UPDATE_SALARY_TRANSACTION_ERROR = 'UPDATE_SALARY_TRANSACTION_ERROR';
export const UPDATE_TRANSACTION_ERROR = 'UPDATE_TRANSACTION_ERROR';
export const UPDATE_SALARY_TRANSACTION_SUCCESS = 'UPDATE_SALARY_TRANSACTION_SUCCESS';
export const UPDATE_TRANSACTION_SUCCESS = 'UPDATE_TRANSACTION_SUCCESS';
export const GET_EMPLOYEE_PAYMENT = 'GET_EMPLOYEE_PAYMENT';
export const GET_EMPLOYEE_PAYMENTS = 'GET_EMPLOYEE_PAYMENTS';
export const REMOVE_DELETE_EMPLOYEE_PAYMENT = 'REMOVE_DELETE_EMPLOYEE_PAYMENT';
export const REMOVE_UPDATE_EMPLOYEE_PAYMENT = 'REMOVE_UPDATE_EMPLOYEE_PAYMENT';
export const SET_DELETE_EMPLOYEE_PAYMENT_ID = 'SET_DELETE_EMPLOYEE_PAYMENT_ID';
export const SET_UPDATE_EMPLOYEE_PAYMENT_ID = 'SET_UPDATE_EMPLOYEE_PAYMENT_ID';

export const ADD_EMPLOYEE_EARNING = 'ADD_EMPLOYEE_EARNING';
export const EMPLOYEE_EARNING_ERROR = 'EMPLOYEE_EARNING_ERROR';
export const GET_EMPLOYEE_EARNING = 'GET_EMPLOYEE_EARNING';
export const GET_EMPLOYEE_EARNINGS = 'GET_EMPLOYEE_EARNINGS';
export const REMOVE_DELETE_EMPLOYEE_EARNING = 'REMOVE_DELETE_EMPLOYEE_EARNING';
export const REMOVE_UPDATE_EMPLOYEE_EARNING = 'REMOVE_UPDATE_EMPLOYEE_EARNING';
export const SET_DELETE_EMPLOYEE_EARNING_ID = 'SET_DELETE_EMPLOYEE_EARNING_ID';
export const SET_UPDATE_EMPLOYEE_EARNING_ID = 'SET_UPDATE_EMPLOYEE_EARNING_ID';


export const GET_EXPENSE_TEMPLATE_ID_DATA = 'GET_EXPENSE_TEMPLATE_ID_DATA';
export const EXPENSE_TEMPLATE_ID_ERROR = 'EXPENSE_TEMPLATE_ID_ERROR';
export const ADD_EXPENSE = 'ADD_EXPENSE';
export const REMOVE_DELETE_EXPENSE = 'REMOVE_DELETE_EXPENSE';
export const REMOVE_UPDATE_EXPENSE = 'REMOVE_UPDATE_EXPENSE';
export const EXPENSE_ERROR = 'EXPENSE_ERROR';
export const GET_EXPENSE = 'GET_EXPENSE';
export const GET_EXPENSES = 'GET_EXPENSES';
export const SET_DELETE_EXPENSE_ID = 'SET_DELETE_EXPENSE_ID';
export const SET_UPDATE_EXPENSE_ID = 'SET_UPDATE_EXPENSE_ID';

export const ADD_EXPENSE_TEMPLATE = 'ADD_EXPENSE_TEMPLATE';
export const REMOVE_DELETE_EXPENSE_TEMPLATE = 'REMOVE_DELETE_EXPENSE_TEMPLATE';
export const REMOVE_UPDATE_EXPENSE_TEMPLATE = 'REMOVE_UPDATE_EXPENSE_TEMPLATE';
export const EXPENSE_TEMPLATE_ERROR = 'EXPENSE_TEMPLATE_ERROR';
export const GET_EXPENSE_TEMPLATE = 'GET_EXPENSE_TEMPLATE';
export const GET_EXPENSE_TEMPLATES = 'GET_EXPENSE_TEMPLATES';
export const SET_DELETE_EXPENSE_TEMPLATE_ID = 'SET_DELETE_EXPENSE_TEMPLATE_ID';
export const SET_UPDATE_EXPENSE_TEMPLATE_ID = 'SET_UPDATE_EXPENSE_TEMPLATE_ID';

export const ADD_SPARE_PART = 'ADD_SPARE_PART';
export const SPARE_PART_ERROR = 'SPARE_PART_ERROR';
export const GET_SPARE_PART_OPTIONS = 'GET_SPARE_PART_OPTIONS';
export const GET_SPARE_PART = 'GET_SPARE_PART';
export const GET_SPARE_PARTS = 'GET_SPARE_PARTS';
export const GET_SPARE_UNITS_WAREHOUSES_DATA = 'GET_SPARE_UNITS_WAREHOUSES_DATA';
export const REMOVE_DELETE_SPARE_PART = 'REMOVE_DELETE_SPARE_PART';
export const REMOVE_UPDATE_SPARE_PART = 'REMOVE_UPDATE_SPARE_PART';
export const SET_DELETE_SPARE_PART_ID = 'SET_DELETE_SPARE_PART_ID';
export const SET_UPDATE_SPARE_PART_ID = 'SET_UPDATE_SPARE_PART_ID';

export const MYTYPE = 'MYTYPE';


