import React, { useEffect, useState} from "react";
import moment from "moment/moment";
import {useDispatch, useSelector} from "react-redux";
import MoneyFormat from "../../layout/MoneyFormat";
import {getMaterialImports, setDeleteMaterialImport} from "../../../actions/material";
import MaterialImportDeleteForm from "../MaterialImportDeleteForm";
import {Trash2} from "react-feather";
import CustomPagePagination from "../../layout/CustomPagePagination";

const MaterialImports = () => {

    const dispatch = useDispatch();
    const {
        material,
        material_imports
    } = useSelector(state => state.material);

    const {id} = material;
    const {current_page, last_page, data} = material_imports;

    useEffect(() => {
        if (id) {
            dispatch(getMaterialImports(id, {}));
        }
    }, [getMaterialImports, id]);


    const [from_date, setFromDate] = useState('');
    const [to_date, setToDate] = useState('');

    const onChangeFromDate = (event) => {
        setFromDate(state => event.target.value);
        const url = new URL(window.location);
        url.searchParams.set('from_date', event.target.value);
        url.searchParams.set('to_date', to_date);
        window.history.pushState({}, '', url);
        const params = {};
        params.to_date = to_date;
        params.from_date = event.target.value;
        dispatch(getMaterialImports(id, params))
    };

    const onChangeToDate = (event) => {
        setToDate(state => event.target.value);
        const url = new URL(window.location);
        url.searchParams.set('to_date', event.target.value);
        url.searchParams.set('from_date', from_date);
        window.history.pushState({}, '', url);
        const params = {};
        params.to_date = event.target.value;
        params.from_date = from_date;
        dispatch(getMaterialImports(id, params))
    };

    const onDeleteMaterialImport = (import_id) => {
        console.log(import_id);
        dispatch(setDeleteMaterialImport(import_id));
    };

    return (

        <div className="w-100 table-responsive px-2">
            <div className="row mb-3">
                <div className="col-md-6">
                    <h4>Material importi</h4>
                </div>
            </div>

            <div className="mb-2 align-items-center d-flex
                                                        justify-content-between">
                <div className="mb-3">
                    <label htmlFor="date1"
                           className="mb-2">dan</label>
                    <input onChange={onChangeFromDate}
                           className="form-control"
                           id="date1" type="date"/>
                </div>

                <div className="mb-3">
                    <label htmlFor="date2"
                           className="mb-2">gacha</label>
                    <input onChange={onChangeToDate}
                           className="form-control"
                           id="date2" type="date"/>
                </div>
            </div>

            <table className="table custom-table table-hover">
                <thead className="table-dark">
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Yetkazuvchi</th>
                    <th scope="col">Miqdori</th>
                    <th scope="col">Narxi</th>
                    <th scope="col">Sana</th>
                    <th scope="col">Amal</th>
                </tr>
                </thead>
                <tbody>
                {
                    data?.map((item, index) => (
                        <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>
                                {item?.supplier?.name}
                            </td>
                            <td>
                                {item?.quantity}
                            </td>
                            <td>
                                <MoneyFormat
                                    value={item?.price || 0}
                                    currency={" so'm"}/>
                            </td>
                            <td>
                                {moment(item?.date).format("YYYY-MM-DD")}
                            </td>
                            <td>
                                <div className="actions">
                                    <Trash2
                                        onClick={() => onDeleteMaterialImport(item.id)}
                                    />
                                </div>
                            </td>
                        </tr>
                    ))
                }

                <tr key={"last_import"}>
                    <th scope="row">#</th>
                    <th>
                        Hamma
                    </th>
                    <th>
                        {data?.reduce((acc, cur) => acc + cur.quantity, 0)}
                    </th>
                    <th>
                        <MoneyFormat
                            value={data?.reduce((acc, cur) => acc + cur.price * cur.quantity, 0) || 0}
                            currency={" so'm"}/>
                    </th>
                    <th>
                        -
                    </th>
                    <th>
                        Jami
                    </th>
                </tr>
                </tbody>
            </table>

            <MaterialImportDeleteForm/>


            <CustomPagePagination
                numberOfPages={last_page}
                getPageItems={getMaterialImports}
                current_page={current_page}
            />

        </div>
    )
}

export default MaterialImports;